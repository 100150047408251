import '../css/app.scss';

const $ = require('jquery');
window.$ = $;
window.jQuery = $;

import 'popper.js';
import 'bootstrap';
// import './fixed_navbar.js';
import './textarea_auto_resize.js';

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-free/js/all";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { faUserCircle } from "@fortawesome/pro-light-svg-icons";
import { faSignIn } from "@fortawesome/pro-light-svg-icons";
import { faBagsShopping } from "@fortawesome/pro-light-svg-icons";
import { faMapMarker } from "@fortawesome/pro-light-svg-icons";
library.add(faSearch, faMapMarker, faCog, faBars, faUserCircle, faSignIn, faBagsShopping);
dom.watch();

