// Dealing with Textarea Height

$(document).ready(function () {
    function calcHeight(value) {
        let numberOfLineBreaks = (value.match(/\n/g) || []).length;
        // min-height + lines x line-height + padding + border
        let newHeight = 40 + numberOfLineBreaks * 26;
        return newHeight;
    }

    let textareas = document.getElementsByTagName('textarea');
    textareas.forEach(element => {
        element.style.height = calcHeight(element.value) + "px";

        element.addEventListener("keyup", () => {
            element.style.height = calcHeight(element.value) + "px";
        });
    });

});